// ::-webkit-datetime-edit {
//     background: skyblue;
//     padding: 1em;
// }

// ::-webkit-datetime-edit-fields-wrapper {
//     background: silver;
// }

// ::-webkit-datetime-edit-text {
//     color: red;
//     padding: 0 0.3em;
// }

// ::-webkit-datetime-edit-month-field {
//     color: blue;
// }

// ::-webkit-datetime-edit-day-field {
//     color: green;
// }

// ::-webkit-datetime-edit-year-field {
//     color: purple;
// }

// ::-webkit-inner-spin-button {
//     color: indigo;
// }

// ::-webkit-calendar-picker-indicator {
//     width: fit-content;
//     &:focus {
//         // background: rgba(221, 144, 0, 0.223);
//         padding: 0px 0px;
//         justify-content: center;
//         border: 1px red;
//     }
// }

@layer components {

    // =================== Input Group ==================
    .input-group * {
        border-radius: 0px;
        align-self: center !important;

        &:first-child {

            &:not(:last-child) {
                margin-inline: 0rem;
                margin-right: 0.125rem;
                border-top-left-radius: 0.375rem;
                border-bottom-left-radius: 0.375rem;
            }
        }

        &:last-child {
            border-radius: 0.375rem;

            &:not(:first-child) {
                border-radius: 0px;
                margin-inline: 0rem;
                margin-left: 0.125rem;
                border-top-right-radius: 0.375rem;
                border-bottom-right-radius: 0.375rem;
            }
        }
    }

    .input-group {
        @apply flex flex-nowrap w-fit justify-items-stretch items-stretch rounded-md bg-white border;

        // input {
        //     &[type=date],
        //     &[type=datetime-local],
        //     &[type=month],
        //     &[type=week] {
        //         display: none !important;
        //         -webkit-appearance: none !important;
        //     }
        // }

        &.transparent-items {
            input {
                border-width: 0px !important;

                &[type=text] {

                    &:first-child,
                    &:last-child {
                        background-color: transparent;
                    }
                }
            }

        }

        &.center-items {

            :only-child,
            :first-child,
            :last-child {
                align-self: center !important;
            }
        }

        &.full-width {
            width: -webkit-fill-available;
            height: fit-content;
        }

        // Size
        &.input-group-sm {

            :only-child,
            :first-child,
            :last-child {
                @apply py-0 px-2 text-sm;
            }
        }

        &.input-group-lg {

            :only-child,
            :first-child,
            :last-child {
                @apply py-2 px-4 text-lg;
            }
        }

        &.input-group-xl {

            :only-child,
            :first-child,
            :last-child {
                @apply py-3 px-5 text-xl;
            }
        }

    }

    textarea {
        min-height: 50px;
    }

    textarea,
    // =================== Input ==================
    input {
        @apply border py-1 px-3 rounded-md text-base PoppinsRegular;

        &:focus:not([type=checkbox], [type=radio]),
        &:hover:not([type=checkbox], [type=radio]) {
            @apply outline-none ring-2 ring-blue-500 ring-offset-1;
        }

        // &:valid {
        //     @apply bg-white;
        // }

        // &:invalid {
        //     @apply bg-red-100;
        // }

        &[type=checkbox] {

            /* Create a custom checkbox */
            &~[class*=checkmark] {
                @apply rounded-md border;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                border-color: #ccc;
            }

            /* On mouse-over, add a grey background color */
            &~[class*=checkmark]:hover {
                background-color: #ccc;
            }

            /* When the checkbox is checked, add a blue background */
            &:checked~[class*=checkmark] {
                background-color: rgb(59, 130, 246);
            }

            /* Create the checkmark/indicator (hidden when not checked) */
            &:checked~[class*=checkmark]:after {
                content: "";
                position: relative;
                display: none;
            }

            /* Show the checkmark when checked */
            &:checked~[class*=checkmark]:after {
                display: block;
            }

            /* Style the checkmark/indicator */
            &:checked~[class*=checkmark]:after {
                left: 35%;
                top: 22%;
                width: 7px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }

        &[type=button],
        &[type=checkbox],
        &[type=color],
        &[type=file],
        &[type=radio],
        &[type=reset],
        &[type=submit] {
            &:hover {
                @apply cursor-pointer;
            }
        }

        &[type=date],
        &[type=datetime-local],
        &[type=month],
        &[type=time],
        &[type=week] {
            @apply flex-row text-center;

            &.reverse {
                @apply flex-row-reverse;

                &[type=time] {
                    @apply pl-0;
                }
            }
        }

        &[type=button],
        &[type=date],
        &[type=datetime-local],
        &[type=email],
        &[type=file],
        &[type=month],
        &[type=number],
        &[type=password],
        &[type=reset],
        &[type=search],
        &[type=submit],
        &[type=tel],
        &[type=time],
        &[type=url],
        &[type=week],
        &[type=text] {
            &.sm {
                @apply py-0 px-2 text-sm;
            }

            &.lg {
                @apply py-2 px-4 text-lg;
            }

            &.xl {
                @apply py-3 px-5 text-xl;
            }
        }

        &[type=checkbox] {}

        &[type=color] {}

        &[type=date] {}

        &[type=datetime-local] {}

        &[type=email] {}

        &[type=file] {}

        &[type=hidden] {}

        &[type=image] {}

        &[type=month] {}

        &[type=number] {}

        &[type=password] {}

        &[type=radio] {}

        &[type=range] {
            @apply py-0 px-0;
        }

        &[type=reset] {}

        &[type=search] {}

        &[type=submit] {}

        &[type=tel] {}

        &[type=text] {}

        &[type=time] {}

        &[type=url] {}

        &[type=week] {}
    }
}