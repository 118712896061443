.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1090;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    -webkit-animation-name: fadeIn;
    /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;
    justify-content: center;
    align-items: center;

    &.modal-bottom {
        align-items: flex-end;
    }

    &.modal-top {
        align-items: flex-start;
    }
}

/* Modal Content */
.modal-content {
    position: fixed;
    left: 2rem;
    right: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: #fefefe;
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    &.modal-bottom {
        top: 50%;
        bottom: 2rem;
        -webkit-animation-name: slideInBottom;
        -webkit-animation-duration: 0.4s;
        animation-name: slideInBottom;
        animation-duration: 0.4s;
    }

    &.modal-top {
        top: 2rem;
        bottom: 50%;
        -webkit-animation-name: slideInTop;
        -webkit-animation-duration: 0.4s;
        animation-name: slideInTop;
        animation-duration: 0.4s;
    }

    &.modal-middle {
        top: 2rem;
        bottom: 2rem;
    }
}

/* The Close Button */
.close {
    color: rgb(123, 123, 123);
    font-size: 20px;
    margin-left: var(--toast-padding-x);
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    padding: 16px;
    border-bottom-width: 2px;
}

.modal-body {
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
}

.modal-footer {
    padding: 16px;
    border-top-width: 2px;
}

/* Add Animation */
@-webkit-keyframes slideInTop {
    from {
        top: -300px;
        bottom: 100%;
        opacity: 0
    }

    to {
        top: 2rem;
        bottom: 50%;
        opacity: 1
    }
}

@keyframes slideInTop {
    from {
        top: -300px;
        bottom: 100%;
        opacity: 0
    }

    to {
        top: 2rem;
        bottom: 50%;
        opacity: 1
    }
}

@-webkit-keyframes slideInBottom {
    from {
        bottom: -300px;
        top: 100%;
        opacity: 0
    }

    to {
        bottom: 2rem;
        top: 50%;
        opacity: 1
    }
}

@keyframes slideInBottom {
    from {
        bottom: -300px;
        top: 100%;
        opacity: 0
    }

    to {
        bottom: 2rem;
        top: 50%;
        opacity: 1
    }
}


@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}