@font-face {
  font-family: PoppinsBlack;
  src: url(assets/fonts/Poppins-Black.ttf);
}

@font-face {
  font-family: PoppinsBlackItalic;
  src: url(assets/fonts/Poppins-BlackItalic.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: PoppinsBoldItalic;
  src: url(assets/fonts/Poppins-BoldItalic.ttf);
}

@font-face {
  font-family: PoppinsExtraBold;
  src: url(assets/fonts/Poppins-ExtraBold.ttf);
}

@font-face {
  font-family: PoppinsExtraBoldItalic;
  src: url(assets/fonts/Poppins-ExtraBoldItalic.ttf);
}

@font-face {
  font-family: PoppinsExtraLight;
  src: url(assets/fonts/Poppins-ExtraLight.ttf);
}

@font-face {
  font-family: PoppinsExtraLightItalic;
  src: url(assets/fonts/Poppins-ExtraLightItalic.ttf);
}

@font-face {
  font-family: PoppinsItalic;
  src: url(assets/fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: PoppinsLight;
  src: url(assets/fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: PoppinsLightItalic;
  src: url(assets/fonts/Poppins-LightItalic.ttf);
}

@font-face {
  font-family: PoppinsMedium;
  src: url(assets/fonts/Poppins-Medium.ttf);
}

@font-face {
  font-family: PoppinsMediumItalic;
  src: url(assets/fonts/Poppins-MediumItalic.ttf);
}

@font-face {
  font-family: PoppinsRegular;
  src: url(assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: PoppinsSemiBoldItalic;
  src: url(assets/fonts/Poppins-SemiBoldItalic.ttf);
}

@font-face {
  font-family: PoppinsThin;
  src: url(assets/fonts/Poppins-Thin.ttf);
}

@font-face {
  font-family: PoppinsThinItalic;
  src: url(assets/fonts/Poppins-ThinItalic.ttf);
}

.PoppinsBlack {
  font-family: PoppinsBlack;
}

.PoppinsBlackItalic {
  font-family: PoppinsBlackItalic;
}

.PoppinsBold {
  font-family: PoppinsBold;
}

.PoppinsBoldItalic {
  font-family: PoppinsBoldItalic;
}

.PoppinsExtraBold {
  font-family: PoppinsExtraBold;
}

.PoppinsExtraBoldItalic {
  font-family: PoppinsExtraBoldItalic;
}

.PoppinsExtraLight {
  font-family: PoppinsExtraLight;
}

.PoppinsExtraLightItalic {
  font-family: PoppinsExtraLightItalic;
}

.PoppinsItalic {
  font-family: PoppinsItalic;
}

.PoppinsLight {
  font-family: PoppinsLight;
}

.PoppinsLightItalic {
  font-family: PoppinsLightItalic;
}

.PoppinsMedium {
  font-family: PoppinsMedium;
}

.PoppinsMediumItalic {
  font-family: PoppinsMediumItalic;
}

.PoppinsRegular {
  font-family: PoppinsRegular;
}

.PoppinsSemiBold {
  font-family: PoppinsSemiBold;
}

.PoppinsSemiBoldItalic {
  font-family: PoppinsSemiBoldItalic;
}

.PoppinsThin {
  font-family: PoppinsThin;
}

.PoppinsThinItalic {
  font-family: PoppinsThinItalic;
}

[class*='font-0.5rem'] {
  font-size: 0.5rem;
  line-height: 0.5rem;
}
[class*='font-0.209rem'] {
  font-size: 0.209rem;
  line-height: 0.209rem;
}

.text-prevent-select {
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
[class*='!text-prevent-select'] {
  -moz-user-select: none !important;
  -khtml-user-select: none !important;
  -o-user-select: none !important;
  -webkit-user-select: none !important; /* Safari */
  -ms-user-select: none !important; /* IE 10 and IE 11 */
  user-select: none !important; /* Standard syntax */
}
