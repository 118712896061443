$opacity: 0;

@layer components {
    .accordion {

        // [class*="accordion-content active"] {
        //     @apply bg-slate-50 p-5;
        // }
    }

    .accordion-title {
        @apply flex flex-row p-4 justify-between cursor-pointer primary-ghost;
    }

    .accordion-content {
        @apply h-0 px-4 py-0 -z-10 relative;
        opacity: $opacity;
        transform: translateY(-10px);
        // transition: all 0.5s;

        @if $opacity==0 {
            @apply hidden;
        }

        @else {
            @apply block;
        }

        &.active {
            @apply h-auto pb-2 z-0 contents;
            opacity: 1;
            transform: translateY(10px);
        }
    }
}