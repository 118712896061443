.spinner-border,
.spinner-grow {
    --spinner-width: 2rem;
    --spinner-height: 2rem;
    --spinner-vertical-align: -0.125em;
    --spinner-border-width: 0.25em;
    --spinner-animation-speed: 0.75s;
    --spinner-animation-name: spinner-border;
    border-right-color: currentcolor;

    &.spinner-grow {
        --spinner-width: 2rem;
        --spinner-height: 2rem;
        --spinner-vertical-align: -0.125em;
        --spinner-animation-speed: 0.75s;
        --spinner-animation-name: spinner-grow;
        background-color: currentColor;
        opacity: 0;
    }

    &.spinner-grow-sm {
        --spinner-width: 1rem;
        --spinner-height: 1rem;
    }

    &.spinner-border-sm {
        --spinner-width: 1rem;
        --spinner-height: 1rem;
        --spinner-border-width: 0.2em;
    }

    &.spinner-grow-lg {
        --spinner-width: 4rem;
        --spinner-height: 4rem;
    }

    &.spinner-border-lg {
        --spinner-width: 4rem;
        --spinner-height: 4rem;
        --spinner-border-width: 0.5em;
    }

    border: var(--spinner-border-width) solid;
    border-right: var(--spinner-border-width) solid transparent;

    -webkit-animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name);
    animation: var(--spinner-animation-speed) linear infinite var(--spinner-animation-name);
    border-radius: 50%;
    display: inline-block;
    height: var(--spinner-height);
    vertical-align: var(--spinner-vertical-align);
    width: var(--spinner-width);
}

@keyframes spinner-border {
    100% {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 0.9;
        -webkit-transform: none;
        transform: none;
    }
}