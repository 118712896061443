.RootBodyPrint {
    @apply p-5;
    margin: 0;
    min-height: 100vh;
    max-height: auto;
    background-color: #000;

    .CetakPembayaran {
        width: 58mm;
        padding: 1mm;
        margin: 0mm auto;
        background: #FFF;

        @page {
            size: 58mm 158mm;
            margin: 0;
        }
    }


    @media print {
        @apply p-0;

        .full-height {
            display: unset;
        }

        .bg-app {
            display: none;
            padding: 0 !important;
        }

        .subpage {
            margin-left: 0px;
        }

        .notprint {
            display: none;
        }

        .page {
            font-family: system-ui;
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
    }
}